import $ from 'jquery';


// Import only the Bootstrap components we need
//import { Util, Dropdown, Offcanvas, Popover } from 'bootstrap';
//import { Navbar } from 'bootstrap';

//Dependences

//import Swiper, {Navigation, Pagination} from 'swiper';


import '../scss/style.scss';

import {removecaritems} from './partials/remove-cart-item';
import {btnheaderbascet} from './partials/btnheaderbascet';

import './partials/searching';

const scrollbarWidth = window.innerWidth - document.body.clientWidth
let headerheight = document.querySelector('.main-header').offsetHeight;
document.body.style.setProperty("--scrollbarWidth", `${scrollbarWidth}px`)
document.body.style.setProperty("--headerheight", `${headerheight}px`)


$(document).ready(function() {
  $(window).on('resize', function(){
    headerheight = document.querySelector('.main-header').offsetHeight;
    document.body.style.setProperty("--headerheight", `${headerheight}px`)
  })

  removecaritems();
  $('.hamburger.mobile-menu').on('click',function(e){
    e.preventDefault();
    //console.log('hamburger');
    $(this).toggleClass('is-active')
    $('.mobile-menu-wrap').toggleClass('show')
    $('.main-header').toggleClass('menu-show')
    //$('.main-header').toggleClass('open')
  })

  $('.megamenu > .has-children > .nav-link ').on('click',function(e){
    e.preventDefault();
    if( $(this).closest('.has-children').find('> .nav-link').hasClass('active')){
      $(this).closest('.has-children').find('> .nav-link').toggleClass('active');
      $(this).closest('.has-children').find('> .megamenu-submenu').toggle()  
    }else {
      $('.megamenu > .has-children > .megamenu-submenu').hide();
      $('.megamenu > .has-children > .nav-link').removeClass('active');
      $(this).closest('.has-children').find('> .nav-link').toggleClass('active');
      $(this).closest('.has-children').find('> .megamenu-submenu').toggle()  
    }
    
  })
  $(document).on('click', function(e) {
    if (event && event.target) {
      var container = $(".megamenu");
      if (!container.is(event.target) && !container.has(event.target).length) {
        $('.megamenu > .has-children > .megamenu-submenu').hide();
        $('.megamenu > .has-children > .nav-link').removeClass('active');
      }
    }
  })

  $('.mobile-menu-wrap .has-children > .nav-link, .mobile-menu-wrap .has-children > .show-submenu').on('click',function(e){
    e.preventDefault();
    $(this).closest('.has-children').find('> .show-submenu').toggleClass('active');
    $(this).closest('.has-children').find('> .nav-link').toggleClass('active');
    $(this).closest('.has-children').find('> .sub-menu').animate({
      height: "toggle",
      opacity: "toggle"
    }, 200 );
  })

  var scroll = 0;
  $(window).on('scroll',function(){
    let curscroll = $(window).scrollTop();
    if (curscroll > scroll){
      // downscroll code
      $('.main-header').removeClass('scrollup');
    } else {
      // upscroll code
      $('.main-header').addClass('scrollup');
    }
    if ((curscroll > 0) ) {
      $('.main-header').addClass('scroll');
    }else {
      $('.main-header').removeClass('scroll');
      $('.main-header').removeClass('scrollup');
        
    }
    scroll = curscroll;
  })


  $('.anime-scroll').on('click',function(e){
    e.preventDefault();
    let thishas = $(this).find('.nav-link').attr('href')
    $('html, body').animate({scrollTop: $(thishas).offset().top - 110}, 1000);
  })
  

  btnheaderbascet();

  if($('#moove_gdpr_cookie_info_bar').length) {
    let cookies = getCookie('moove_gdpr_popup');
    //console.log(cookies);
    if (cookies == null) {
      //console.log('jeeee');
      var timerID = setInterval(function() {
        if($('#moove_gdpr_cookie_info_bar').is(':visible')) {
          $('#moove_gdpr_cookie_info_bar .moove-gdpr-infobar-settings-btn').trigger('click');
          $('.gdpr_lightbox-wrap').removeAttr('data-gdpr_lightbox-close');
          clearInterval(timerID);
        }
  
        //console.log(timerID)
      }, 1000);
    }
  }

});


function setCookieTrial() {
  var d = new Date();
  d.setTime(d.getTime() + (2*24*60*60*1000));
  var expires = "expires="+ d.toUTCString();
  document.cookie = "noticepopup=click;" + expires;
}


function setCookie(name,value,days) {
  var expires = "";
  if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}
function eraseCookie(name) {
  document.cookie = name+'=; Max-Age=-99999999;';
}
