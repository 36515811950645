import $ from 'jquery';

$(document).ready(function() {
    var currentSearchRequest = null;  
  $('.search-form .form-control').on('keyup',function(e){
    e.preventDefault();
    //$('.loading-loader').show();
    //console.log('pisem')
    $('.search-form .btn .spinner-border').show();
    $('.search-form .btn .icon-search').hide();
    let thisval = $(this).val();
    if(thisval != '') {
      var data = {
      action: 'ajax_search_lemon',
      search: thisval
      };
      if(currentSearchRequest != null) {
          currentSearchRequest.abort();
      }
      currentSearchRequest = $.ajax({
      type: 'POST',
      url: ajax_url,
      data: data,
      success: function (response) {
        if(response.success == true) {
        $('.search-wrap .result-wrap').html(response.result);
        }else {
        }
        $('.search-result-products-wrap .close').on('click',function(e){
          e.preventDefault();
          $('.search-result-products-wrap').remove();
          $('.search-form .form-control').val('');
        })
          //$('.loading-loader').hide();
          $('.search-form .btn .spinner-border').hide();
          $('.search-form .btn .icon-search').show();
      },
      error: function(response) {
          //$('.loading-loader').hide();
      }
      });    
    }else {
      if(currentSearchRequest != null) {
        currentSearchRequest.abort();
        $('.search-form .btn .spinner-border').hide();
        $('.search-form .btn .icon-search').show();
      }
      $('.search-wrap .result-wrap').html('');
    }
    
  })
});